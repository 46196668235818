import { easings, Easings } from "./easings";
import { ItemPosition } from "./hooks";

export const partOf = (n: number, max: number): number => n / max;

export const amountOf = (part: number, max: number): number => part * max;

type PositionValue = [position: number, value: number];

export type MakeEase = (
  from: PositionValue,
  to: PositionValue,
  easing?: Easings
) => (value: number) => number | null;

export const makeEase: MakeEase = (from, to, easing = "linear") => (v: number) => {
  const fwd = from[0] < to[0];

  const progress = v - from[0];
  const end = to[0] - from[0];
  const valueEnd = to[1] - from[1];

  const started = fwd ? v >= from[0] : v <= from[0];
  const ended = fwd ? v >= to[0] : v <= to[0];

  if (started && !ended) {
    return amountOf(easings[easing](partOf(progress, end)), valueEnd) + from[1];
  }

  return null;
};

type MakeRotationTransform<F> = (options?: { threshold?: number; maxRotation?: number }) => F;

export type TransformFunction = (
  middlePosition: number,
  itemPosition: ItemPosition
) => string | undefined;

export const makeRotationTransform: MakeRotationTransform<TransformFunction> = ({
  threshold: th = 300,
  maxRotation: mr = 60,
} = {}) => (pos, [begin, end] = [0, 0]) => {
  const rotateBefore = makeEase([begin - th, -mr], [begin, -30]);
  const rotateAfter = makeEase([end, 30], [end + th, mr]);
  const toFlat = makeEase([begin, -30], [begin + 70, 0], "easeOutQuad");
  const fromFlat = makeEase([end - 70, 0], [end, 30], "easeInQuad");

  const deg =
    rotateBefore(pos) ??
    rotateAfter(pos) ??
    toFlat(pos) ??
    fromFlat(pos) ??
    (pos > end ? mr : pos < begin ? -mr : 0);

  return `rotateY(${deg}deg)`;
};

export type Timer = number | NodeJS.Timeout;

export const debounce = <T extends unknown[]>(
  f: (...args: T) => void,
  eager?: boolean
): ((...args: T) => void) => {
  let timer: Timer;
  let eagerDone = false;

  return (...args: T) => {
    if (eager && !eagerDone) {
      eagerDone = true;

      return f(...args);
    }

    clearTimeout(timer as number);

    timer = setTimeout(() => {
      f(...args);
    }, 500);
  };
};

import { w } from "./ssr";

export const getStyle = (
  el: Element | undefined | null,
  rule: keyof CSSStyleDeclaration
): string => {
  if (!el) return "";

  const val = w?.getComputedStyle(el)[rule];

  return typeof val === "string" ? val : "";
};

export const rect = (el: HTMLElement): DOMRect => el.getBoundingClientRect();

export const def = <T>(x: T): x is NonNullable<T> => x !== undefined;

export { w as window, d as document } from "./ssr";
